/* Common CSS */
.est-screens {
  /* overflow: hidden; */
  position: relative;
}

.est-screens::after,
.success-screen.design-props::after {
  content: "";
  top: 45%;
  z-index: -1;
  width: 425px;
  right: -120px;
  height: 625px;
  position: absolute;
  background-size: cover;
  background-image: url(../../../images/phone-after.png);
}

.success-screen.design-props::after {
  right: 0;
}

.rtl .est-screens::after,
.rtl .success-screen.design-props::after {
  right: auto;
  left: -120px;
  transform: rotate(180deg);
}

.rtl .success-screen.design-props::after {
  left: 0;
}

.est-screens::before,
.success-screen.design-props::before {
  content: "";
  bottom: 0;
  left: 55px;
  z-index: -1;
  height: 74px;
  width: 318px;
  position: absolute;
  background-size: cover;
  background-image: url(../../../images/phone-details-after.png);
}

.rtl .est-screens::before,
.rtl .success-screen.design-props::before {
  left: auto;
  right: 55px;
}

.est-screens .container::before,
.success-screen.design-props>div::before {
  content: "";
  left: 0;
  top: 20%;
  z-index: -1;
  width: 60px;
  height: 110px;
  position: absolute;
  background-size: contain;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: url(../../../images/semi_blu.png);
}

.rtl .est-screens .container::before,
.rtl .success-screen.design-props>div::before {
  right: 0;
  left: auto;
  transform: rotate(180deg);
}

.est-screens h4 {
  font-weight: 800;
}

.est-screens p {
  line-height: 22px;
}

.ah-btn.primary-btn.bg-purple {
  margin-left: 0;
  background-color: #ffb71b;
}

.ah-btn.primary-btn.bg-purple:hover {
  color: #ffb71b !important;
  border-color: #ffb71b;
  background-color: transparent;
}

.est-screens .form-control {
  padding: 15px;
  font-size: 14px;
  border-radius: 8px;
  border-color: #ddd;
}

.est-screens form .react-tel-input input {
  height: 52px;
}

/* Button styles */
.est-screens input[type="submit"],
.est-screens button[type="submit"],
.est-screens .back-btn,
.car-images-modal input[type="submit"],
.car-images-modal button[type="submit"],
.car-images-modal .back-btn {
  color: #fff;
  display: block;
  font-weight: 600;
  transition: 0.3s;
  text-align: center;
  padding: 10px 30px;
  margin: 25px auto 0;
  border-radius: 0 0 25px;
  text-transform: uppercase;
  border: 1px solid #ffb71b;
  background-color: #ffb71b;
}

.est-screens input[type="submit"]:hover,
.est-screens button[type="submit"]:hover,
.est-screens .back-btn:hover,
.car-images-modal input[type="submit"]:hover,
.car-images-modal button[type="submit"]:hover,
.car-images-modal .back-btn:hover {
  color: #ffb71b;
  background-color: transparent;
}

.est-screens .back-btn,
.car-images-modal .back-btn {
  margin: 25px 20px 0 0;
  border-radius: 0 0 0 25px;
}

.rtl .est-screens .back-btn,
.rtl .car-images-modal .back-btn {
  margin: 25px 0 0 20px;
  border-radius: 0 0 25px 0;
}

.rtl .est-screens input[type="submit"],
.rtl .car-images-modal input[type="submit"] {
  border-radius: 0 0 0 25px;
}

/* Custom checkbox */
.custom-check {
  opacity: 0;
  position: absolute;
}

.custom-check+label {
  cursor: pointer;
  user-select: none;
  position: relative;
  line-height: 22px;
}

.custom-check+label:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: -2px 12px 0 0;
  display: inline-block;
  vertical-align: text-top;
  border: 1px solid #05c3de;
}

.custom-check:checked+label:before {
  background-color: #05c3de;
}

.custom-check:checked+label:after {
  top: 9px;
  left: 5px;
  width: 2px;
  height: 2px;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background-color: #fff;
  box-shadow:
    2px 0 0 #fff,
    4px 0 0 #fff,
    4px -2px 0 #fff,
    4px -4px 0 #fff,
    4px -6px 0 #fff,
    4px -8px 0 #fff;
}

.rtl .custom-check+label:before {
  margin: 5px 0 0 12px;
}

.rtl .custom-check:checked+label:after {
  top: 14px;
  left: auto;
  right: 13px;
}

.custom-check:disabled+label:before {
  border-color: #eee;
}

/* Welcome screen */
.est-welcome .heading {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-end;
}

.est-welcome h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 42px;
}

.est-welcome h2 span {
  display: block;
  color: #05c3de;
  text-align: left;
}

.rtl .est-welcome h2 span {
  text-align: right;
}

.quotation-services {
  padding-top: 0;
}

.quotation-services h2,
.quotation-services h2+p {
  text-align: left;
}

.rtl .quotation-services h2,
.rtl .quotation-services h2+p {
  text-align: right;
}

.est-welcome .heading>span {
  font-size: 14px;
  color: #05c3de;
  font-weight: 600;
  line-height: 18px;
  border-radius: 6px;
  padding: 5px 8px 4px;
  margin: 0 0 12px 20px;
  border: 1px solid #05c3de;
  background-color: transparent;
}

.rtl .est-welcome .heading>span {
  margin: 0 20px 0 0;
}

.offer-display {
  margin: 0 0 15px;
}

.offer-display span.prices {
  font-size: 24px;
  font-weight: 600;
  position: relative;
}

.offer-display span.original::after {
  content: "";
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
  transform: rotate(0deg);
  border-top: 2px solid #212529;
}

.offer-display span.discounted {
  color: #05c3de;
  margin: 0 0 0 12px;
}

.rtl .offer-display span.discounted {
  margin: 0 12px 0 0;
}

.offer-msg {
  color: #888;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 5px;
  display: inline-block;
}

.rtl .offer-msg {
  margin: 0 5px 0 0;
}

.est-welcome .desc span {
  font-size: 14px;
}

.est-welcome img {
  max-width: 100%;
}

.est-welcome .request-btn {
  margin-top: 35px;
}

.steppers {
  padding-top: 0;
}

.steppers #RFS-Label {
  font-size: 13px;
  line-height: 15px;
}

.rtl .steppers {
  direction: ltr;
  transform: rotateY(180deg);
}

.rtl .steppers #RFS-Label {
  transform: rotateY(180deg);
}

.rtl .steppers button {
  transform: rotateY(180deg);
}

.steppers #RFS-ConnectorContainer {
  display: none;
}

.steppers button {
  width: 50px;
  height: 50px;
  border: none;
  margin: 0px;
  display: flex;
  padding: 0px;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
}

.steppers button.completed {
  background-color: #23c275;
}

.steppers button.completed:hover {
  background-color: #2ed986;
}

.welcome-step button,
.steppers button.active {
  background-color: #05c3de;
}

.steppers button.active:hover {
  background-color: #0cdcfa;
}

/* Personal details */
.personal-details .divider,
.car-details .divider {
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.personal-details .est-phone-number {
  width: 100%;
  display: flex;
  direction: ltr;
}

.personal-details .divider>div:not(:first-child) {
  width: 75%;
  margin: 0 0 10px 10px;
}

.rtl .personal-details .divider>div:not(:first-child) {
  margin: 0 10px 10px 0;
}

.otp-inp-group {
  direction: ltr;
}

.otp-inp-group .form-control::selection {
  background-color: #ddd;
}

/* Vehicle details */
.car-details .divider>div {
  width: 100%;
}

.car-details .divider>div:not(:first-child) {
  margin: 0 0 10px 10px;
}

.rtl .car-details .divider>div:not(:first-child) {
  margin: 0 10px 10px 0;
}

.car-details .divider>div.mx-0:first-child input {
  border-radius: 8px 0 0 8px;
}

.car-details .divider>div.mx-0:last-child input {
  border-radius: 0 8px 8px 0;
  border-width: 1px 1px 1px 0;
}

.rtl .car-details .divider>div.mx-0:first-child input {
  border-radius: 0 8px 8px 0;
  border-width: 1px 1px 1px 0;
}

.rtl .car-details .divider>div.mx-0:last-child input {
  border-width: 1px;
  border-radius: 8px 0 0 8px;
}

.car-details .select__control {
  margin: 0;
}

/* Damage details */
.damage-details .divider {
  margin-bottom: 25px;
}

.damage-details label,
.user-guide label {
  color: #666;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.damage-details textarea {
  height: 100px;
  line-height: 20px;
}

.damage-details p,
.user-guide p {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.user-guide span {
  display: block;
  font-size: 14px;
  cursor: pointer;
  color: #05c3de;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
}

.rtl .user-guide span {
  text-align: left;
}

.damage-details .insurance-pdf span {
  cursor: pointer;
  color: #05c3de;
  font-weight: 500;
  line-height: 18px;
}

/* Images upload modal */
.car-images-modal .modal-content {
  padding: 40px 60px;
}

.car-images-modal h4 {
  margin-bottom: 22px;
}

.car-images-modal h4 span {
  color: #05c3de;
}

.drag-drop-file label {
  width: 100%;
  height: 320px;
  display: flex;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f8ff;
  border: 2px dashed #384eb74d;
}

.drag-drop-file label h5,
.drag-drop-file label p {
  cursor: pointer;
}

.drag-drop-file label h5 span {
  cursor: pointer;
  color: #05c3de;
  text-decoration: underline;
}

.drag-drop-file label>img {
  width: 70px;
  margin-bottom: 15px;
}

.drag-drop-file .label {
  color: #000;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.drag-drop-file input {
  max-width: 0;
}

.damage-img-inp label h5 {
  font-size: 16px;
  margin-bottom: 5px;
}

.damage-img-inp label h5 span {
  font-size: 15px;
  display: inline;
}

.damage-img-inp label .img-formats {
  margin: 0;
  font-size: 12px;
}

.loading {
  width: 100%;
  margin-top: 20px;
}

.loading__text {
  margin: 0;
  color: #888;
  font-size: 16px;
}

.loading__bar {
  width: 80%;
  height: 2px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 10px;
  background-color: #ddd;
}

.loading__bar::after {
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  background-color: #05c3de;
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0% {
    left: -50%;
  }

  100% {
    left: 150%;
  }
}

.file-track {
  width: 80%;
  margin-top: 20px;
  padding: 10px 15px 0;
  border-top: 2px solid #ddd;
}

.file-track span {
  line-height: 16px;
  word-break: break-all;
  display: inline-block;
}

.file-track span:last-child {
  direction: ltr;
}

.file-track div i {
  height: 22px;
  display: flex;
  color: #fff;
  cursor: pointer;
  min-width: 22px;
  font-size: 12px;
  transition: 0.3s;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
}

.file-track div i:hover {
  transition: 0.3s;
  background-color: #ff4a4a;
}

.file-track p {
  color: #676767;
  margin-bottom: 8px;
}

.file-track .success {
  color: #2ed986;
  line-height: 24px;
}

.file-track .success svg {
  transform: translateY(1px);
}

.image-upload-area {
  margin: 20px auto 0;
}

.image-upload-area>div {
  padding: 0 15px;
  margin-bottom: 35px;
}

.image-upload-area>div>div {
  padding-top: 18px;
  position: relative;
  border-top: 1px solid #d9d9d9;
}

.image-upload-area>div:last-child {
  margin-bottom: 20px;
}

.image-upload-area h6 {
  color: #212529;
  font-weight: 600;
  margin-bottom: 15px;
}

.image-upload-area p {
  color: #666;
  font-size: 14px;
  line-height: 18px;
  margin: 4px 0 18px;
}

.image-upload-area img {
  width: 100%;
}

.image-upload-area svg,
.display-img svg {
  top: 62px;
  right: 8px;
  /* width: 28px;
  height: 28px; */
  display: flex;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  border: 2px solid #fff;
  background-color: #23c275;
  padding: 7px;
}

.display-img svg {
  top: 10px;
}

.rtl .image-upload-area svg,
.rtl .display-img svg {
  left: 8px;
  right: auto;
}

.custom-image-area .metadata {
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
  justify-content: space-between;
}

.custom-image-area .metadata span {
  font-size: 20px;
  font-weight: 500;
}

.custom-image-area .metadata svg {
  cursor: pointer;
  font-size: 18px;
  color: #ff4a4a;
  margin-bottom: 5px;
}

.custom-image-area .add-more {
  margin: 20px 0 30px;
}

.custom-image-area .add-more b {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  border-radius: 8px;
  padding: 14px 16px;
  background-color: #05c3de;
}

.rtl .custom-image-area .add-more b {
  font-size: 12px;
  padding: 10px 16px;
}

.custom-image-area .add-more b:hover {
  transition: 0.3s;
  background-color: #06b9d1;
}

.custom-image-area .row>div {
  margin-bottom: 20px;
}

.rtl .custom-image-area>div {
  padding: 0 0 0 20px;
}

.custom-image-area h6 {
  padding: 0;
  font-size: 18px;
  color: #212529;
  margin-bottom: 5px;
}

.custom-image-area p {
  padding: 0;
  font-size: 14px;
  margin-bottom: 12px;
}

.custom-image-area p span {
  cursor: pointer;
  color: #05c3de;
  font-weight: 500;
  line-height: 18px;
}

.custom-image-area span {
  padding: 0;
  display: block;
  font-size: 14px;
  margin-top: 8px;
}

.custom-image-area span.img-formats {
  font-size: 12px;
}

.custom-image-area label {
  display: flex;
  height: 200px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
}

.custom-image-area input[type="file"] {
  display: none;
}

.custom-image-area i.fa-camera {
  color: #ddd;
  font-size: 45px;
}

.img-formats {
  margin: 0;
  color: #888;
  font-size: 14px;
  font-weight: 400;
}

.vehicle-docs {
  margin: 35px auto 15px;
}

.vehicle-docs h6 {
  padding: 0;
  display: flex;
  color: #212529;
  margin-bottom: 5px;
}

.vehicle-docs .how-to {
  margin-bottom: 15px;
}

.vehicle-docs .how-to span {
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #05c3de;
}

.vehicle-docs img {
  margin-bottom: 0;
  width: 100% !important;
}

.vehicle-docs i.fa-question {
  margin: 0 10px;
  cursor: pointer;
  color: #05c3de;
}

.vehicle-docs label {
  height: 250px;
  position: relative;
}

.custom-image-area .display-img img {
  max-width: 100%;
  max-height: 200px;
}

.display-img img {
  width: 100%;
  padding: 8px;
  max-height: 250px;
}

/* Summary step */
.summary-step>div {
  margin: 0 auto;
}

.summary-step .data {
  padding: 40px;
  border-radius: 24px;
  margin-bottom: 25px;
  background-color: #f8f8ff;
}

.summary-step .data>div:not(:last-child) {
  margin-bottom: 20px;
}

.summary-step .data h6 {
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ddd;
}

.summary-step .data p {
  font-size: 14px;
  margin: 5px 0 0;
}

.summary-step .data p span {
  color: #666;
  font-weight: 500;
}

.summary-step b {
  cursor: pointer;
  color: #05c3de;
}

/* Agreements step */
.agreements-step {
  margin: 0 auto;
}

.agreements-step>div {
  margin: 0 auto 12px;
}

.agreements-step label {
  margin: 0 0 0 10px;
}

.rtl .agreements-step label {
  margin: 0 10px 0 0;
}

.agreements-step label span {
  font-size: 18px;
  cursor: pointer;
}

.agreements-step label a {
  margin: 0 6px;
  color: #05c3de !important;
}

.agreements-step label a:hover {
  text-decoration: underline !important;
}

/* Success screen */
.success-screen h2 {
  font-size: 42px;
  font-weight: 800;
  color: #05c3de;
}

.success-screen h4 {
  font-weight: 800;
}

.success-screen p {
  color: #212529;
  line-height: 24px;
}

.success-screen img {
  margin-bottom: 35px;
}

/* Custom styled steppers */
.styled-stepper .steppers button {
  width: 90%;
  border-radius: 0;
  border-radius: 2px;
  padding-bottom: 18px;
  background-color: transparent;
  border-bottom: 4px solid #ddd;
}

.styled-stepper .steppers button:hover {
  background-color: transparent;
}

.styled-stepper .steppers button.active,
.styled-stepper .steppers button.completed {
  border-color: #05c3de;
}

.styled-stepper .steppers button span {
  display: grid;
  justify-items: center;
}

/* Image upload guidance modal */
.autohub-modal {
  padding: 30px 60px 60px;
}

.image-guide-modal-header {
  width: 100%;
  text-align: center;
  margin: 0 0 30px 0;
}

.image-guide-modal-header h4 {
  font-size: 32px;
  font-weight: 700;
  color: #05c3de;
}

.image-guide-modal-header p {
  max-width: 580px;
  line-height: 22px;
  margin: 0 auto 35px;
}

.image-guidance-wrapper .carWrapper h6 {
  margin: 0;
  font-weight: 600;
}

.image-guidance-wrapper .carWrapper {
  padding: 20px;
  margin-top: 10px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid #05c3de;
}

.image-guidance-wrapper .carWrapper {
  height: 100%;
}

.image-guidance-wrapper .carWrapper img {
  margin-top: 20px;
  max-width: 100%;
}

.autohub-modal .close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.autohub-modal .close-icon svg,
.image-viewer .close-icon svg,
.order-detail-modal .user-detail-icon svg {
  width: 18px;
  height: 18px;
  padding: 4px;
  display: flex;
  font-size: 18px;
  cursor: pointer;
  font-weight: 200;
  transition: 0.3s;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid #000;
}

.autohub-modal .close-icon svg:hover {
  color: #fff;
  transition: 0.3s;
  background-color: #000;
}

/* 360 car image */
.webrotate360 {
  position: relative;
  margin: 30px auto 50px !important;
}

.webrotate360 img {
  transform: scale(1.5);
  width: 450px !important;
  height: 300px !important;
  margin-top: 10px !important;
  margin-left: 153px !important;
}

.webrotate360::before {
  top: 0;
  right: 0;
  z-index: 111;
  color: #ddd;
  font-size: 14px;
  position: absolute;
  width: fit-content;
  background-color: #fff;
  content: "Powered by autohub";
}

#wr360_menu_webrotate360 {
  display: none !important;
}

/* Image viewer */
.image-viewer {
  background-color: rgba(0, 0, 0, 0.85);
}

.image-viewer .modal-content {
  border: 0;
  padding: 30px;
  background-color: transparent;
}

.image-viewer .close-icon {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
}

.image-viewer .close-icon svg {
  color: #fff;
  cursor: pointer;
  border-color: #fff;
}

.image-viewer .close-icon svg:hover {
  color: #000;
  background-color: #fff;
}

.image-viewer .image-view {
  margin: auto;
  max-width: fit-content;
}

/* Vin number modal */
.vin-num-modal h4 {
  color: #05c3de;
  margin-bottom: 5px;
}

.vin-num-modal p {
  margin-bottom: 25px;
}

.vin-num-modal .img-container {
  margin: auto;
  max-width: 720px;
}

.car-exist .modal-content {
  border: 0;
  padding: 30px;
  background-color: transparent;
}

.car-exist .close-icon {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
}

.car-exist .close-icon svg {
  color: #000;
  cursor: pointer;
  border-color: #fff;
}

.car-exist .modal-content {
  background-color: #fff;
}

.car-exist .image-view {
  margin: auto;
  max-width: fit-content;
}

.car-exist h5 {
  text-align: center;
  padding-bottom: 20px;
}

.no-comments h4 {
  margin: 90px 0px;
  text-align: center;
}